import { inject, Pipe, PipeTransform } from '@angular/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'staticFile',
  standalone: true
})
export class StaticFilePipe implements PipeTransform {
  private downloadPath = "/admin/file-data/download/"
  private readonly service = inject(LocalStorageService);
  transform(value: string, size?: 's' | 'm' | 'lg' | null, hasToken: boolean = false): string {
    if (value) {
      if (size) {
        return `${environment.api_url}${this.downloadPath}${value}?image_size=${size}`; //+ value + '?image_size=' + size`;
      } else {
        if (hasToken) {
          return `${environment.api_url}${this.downloadPath}${value}?image_size=${size}&token=${this.service.get(LocalStorageEnum.token)}`; 
          // return environment.file_static_url + value + '?token=' + this.service.get(LocalStorageEnum.token);
        } else {
          return `${environment.api_url}${this.downloadPath}${value}`;
          // return environment.file_static_url + value;
        }
      }
    }
    return '';
  }
}
